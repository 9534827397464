import React from 'react'

export function Title () {
  const [templeColor, setTempleColor] = React.useState('#2D2A32')

  return (
    <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
      <span onClick={() => setTempleColor('#EAE151')}>
        temple of
        <br />
        sinners
      </span>
    </div>
  )
}
