import React from 'react'
import * as THREE from 'three'
import { Canvas } from '@react-three/fiber'

import { ParticlesEnv } from './components/Particles'
import { Carousel } from './components/Carousel'

export const App = () => {
  // gl={{ antialias: false }}
  // camera={{ position: [0, 2.5, 5], fov: 35 }}
  // onCreated={(state) => {
  //   state.gl.toneMapping = THREE.NoToneMapping
  // }}

  const glSettings = {
    antialias: false,
    toneMapping: THREE.CineonToneMapping
  }

  const cameraAttributes = {
    position: [0, 0, 1],
    fov: 35
  }

  return (
    <>
      <Canvas gl={glSettings} dpr={[1, 1.5]} camera={cameraAttributes}>
        {/*       <Flower /> */}
        {/*     <Carousel /> */}
        <ParticlesEnv />
      </Canvas>
    </>
  )
}
