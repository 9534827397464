import React from 'react'

export function Overlay () {
  const [templeColor, setTempleColor] = React.useState('#2D2A32')

  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <span style={{ position: 'absolute', bottom: 40, left: 90, fontSize: '13px', color: templeColor }} onClick={() => setTempleColor('#EAE151')}>
        temple of
        <br />
        sinners
      </span>

      <div style={{ position: 'absolute', top: 40, left: 40, fontSize: '13px' }}>pierpaol.ooo —</div>

      <div style={{ position: 'absolute', bottom: 40, right: 40, fontSize: '13px' }}>Coming soon 2024</div>
    </div>
  )
}
