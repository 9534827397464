import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { render, events, extend } from '@react-three/fiber'
import './styles.css'
import { App } from './App'

import { Overlay } from './components/Overlay'
import { Title } from './components/Title'

createRoot(document.getElementById('root')).render(
  <>
    <Suspense fallback={null}>
      <App />
    </Suspense>
    <Title />
    <Overlay />
  </>
)
/*
{/*     <Logo style={{ position: 'absolute', bottom: 40, left: 40, width: 30 }} /> */
// extend(THREE)

// window.addEventListener('resize', () =>
//   render(
//   <>
//       <App />
//   </>,
//   document.querySelector('canvas'), {
//     events,
//     linear: true,
//     camera: {
//       fov: 35,
//       position: [0, 0, 1]
//     },
//     // https://barradeau.com/blog/?p=621
//     // This examples needs WebGL1 (?)
//     gl: new THREE.WebGL1Renderer({
//       canvas: document.querySelector('canvas'),
//       antialias: true,
//       alpha: true
//     })
//   })
// )

// window.dispatchEvent(new Event('resize'))
